import { styled, globalStitches } from '@sparky/stitches';

export const ArticleImageContainer = styled('div', {
  position: 'relative',
});

export const ArticleImageHeadingContainer = styled('div', {
  position: 'absolute',
  width: '100%',
  bottom: 0,
});

export const globalStyles = globalStitches({
  body: {
    backgroundColor: '$backgroundPrimary !important',
  },
});
