import { FC } from 'react';

import { ArticleImageContainer, ArticleImageHeadingContainer, globalStyles } from '@custom-components/Article';
import { useFormatter } from '@i18n';
import { Placeholder, mapImage, useLayoutData } from '@sitecore/common';
import { wrap } from '@sitecore/common';
import { ArticleRendering, ArticleTag } from '@sitecore/types/manual/Article';
import { Bleed, Box, Heading, Image, PageGrid, Stack, Text } from '@sparky';
import { useMediaQuery } from '@sparky/hooks';

const ArticleHeading: FC<{
  title: string;
  subTitle: string;
  headingLevel: ArticleRendering['params']['headingLevel'];
  tags?: ArticleTag[];
  byline?: string;
}> = ({ title, subTitle, headingLevel, tags, byline }) => {
  return (
    <Stack gap="5">
      {tags && (
        <Text size={{ initial: 'BodyL', md: 'BodyXL' }} weight="bold">
          {tags.map(tag => tag.displayName).join(', ')}
        </Text>
      )}
      <Heading as={headingLevel ?? 'h1'} size={{ initial: 'M', md: 'XL' }}>
        {title}
      </Heading>
      {subTitle ? <Text size={{ initial: 'BodyM', md: 'BodyL' }}>{subTitle}</Text> : null}
      {byline ? (
        <Text size={{ initial: 'BodyM', md: 'BodyL' }} color="textLowEmphasis">
          {byline}
        </Text>
      ) : null}
    </Stack>
  );
};

const Article: FC<ArticleRendering> = ({ fields, params, placeholders }) => {
  const imageProps = mapImage(fields.image);
  const isDesktop = useMediaQuery('md');
  const { date } = useFormatter();
  globalStyles();

  const {
    route: { fields: routeFields = {} },
  } = useLayoutData();

  const tags = routeFields?.tags as unknown as ArticleTag[] | undefined;

  const byline =
    fields.author?.value && fields.publicationDate?.value
      ? wrap(
          fields?.bylineText,
          fields.bylineText?.value
            .replace('{author}', fields.author.value)
            .replace('{date}', date.long(fields.publicationDate.value)),
        )
      : null;

  const articleHeading = (
    <ArticleHeading
      title={wrap(fields?.title)}
      subTitle={wrap(fields?.subTitle)}
      headingLevel={params?.headingLevel}
      tags={tags}
      byline={byline}
    />
  );

  return (
    <PageGrid as="article">
      <PageGrid.Item gridColumn={{ initial: '1/-1' }}>
        {imageProps.src ? (
          <ArticleImageContainer>
            <Bleed horizontal={{ initial: 'gridGutter', xl: 0 }}>
              <Image
                src={imageProps.src}
                sources={imageProps.sources}
                alt={imageProps.alt}
                width="100%"
                aspectRatio={imageProps.aspectRatio}
                maxHeight="640px"
                objectFit="cover"
                hasLazyLoad={false}
              />
            </Bleed>
            {isDesktop ? (
              <ArticleImageHeadingContainer>
                <Box paddingX={{ lg: '16', xl: '32' }}>
                  <Box paddingX="16" paddingTop="10" backgroundColor="backgroundPrimary">
                    {articleHeading}
                  </Box>
                </Box>
              </ArticleImageHeadingContainer>
            ) : null}
          </ArticleImageContainer>
        ) : null}
        <Box paddingX={{ lg: '16', xl: '32' }}>
          <Box paddingX={{ md: '16' }} paddingY="10">
            <Stack gap="10">
              {!isDesktop ? <>{articleHeading}</> : null}
              <Placeholder name="jss-article-main" />
            </Stack>
          </Box>
        </Box>
      </PageGrid.Item>
    </PageGrid>
  );
};

export default Article;
